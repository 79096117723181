import DateTime from "./DateTime";

// Converts between a form object compatible with the Vue frontend
// and an object submittable for the API

export default {
  formToSubmission(form) {
    // Deep clone form data
    const formStringified = JSON.stringify(Object.assign({}, form));
    const f = JSON.parse(formStringified);

    // Month / year
    const monthYear = DateTime.yearmonthstringToYearMonth(f.month);
    const year = monthYear[0];
    const month = monthYear[1];

    // Company
    const company = f.company;

    // Afgiftsfri
    const afgiftsfri = {};
    for (const prop in f.afgiftsfri) {
      const item = f.afgiftsfri[prop];
      afgiftsfri[prop] = {
        hl: _parseInputToSubmission(item.hl),
        alc: _parseInputToSubmission(item.alc),
        ontradehl: _parseInputToSubmission(item.ontradehl),
      };
    }

    // Afgiftspligtig
    const afgiftspligtig = {};
    for (const prop in f.afgiftspligtig) {
      const item = f.afgiftspligtig[prop];
      afgiftspligtig[prop] = {
        hl: _parseInputToSubmission(item.hl),
        alc: _parseInputToSubmission(item.alc),
        ontradehl: _parseInputToSubmission(item.ontradehl),
      };
    }

    const sub = {
      year,
      month,
      company,
      afgiftsfri,
      afgiftspligtig,
    };

    return sub;
  },
  submissionToForm(form, submission) {
    const monthYearString = DateTime.yearMonthToYearmonthstring(
      submission.year,
      submission.month
    );
    form.month = monthYearString;
    form.company = submission.company;

    // Afgiftsfri
    for (const prop in form.afgiftsfri) {
      const item = submission.afgiftsfri[prop];
      form.afgiftsfri[prop].hl = _parseSubmissionToInput(item.hl);
      form.afgiftsfri[prop].alc = _parseSubmissionToInput(item.alc);
      form.afgiftsfri[prop].ontradehl = _parseSubmissionToInput(item.ontradehl);
    }

    // Afgiftspligtig
    for (const prop in form.afgiftspligtig) {
      const item = submission.afgiftspligtig[prop];
      form.afgiftspligtig[prop].hl = _parseSubmissionToInput(item.hl);
      form.afgiftspligtig[prop].alc = _parseSubmissionToInput(item.alc);
      form.afgiftspligtig[prop].ontradehl = _parseSubmissionToInput(
        item.ontradehl
      );
    }

    return form;
  },
  parseInputToSubmission: _parseInputToSubmission,
  parseSubmissionToInput: _parseSubmissionToInput,
};

function _parseInputToSubmission(value) {
  return parseFloat(value.replace(",", "."));
}

function _parseSubmissionToInput(value, round) {
  if (round) {
    const rounded = Math.round(value * 100) / 100;
    return rounded.toString().replace(".", ",");
  }
  return value.toString().replace(".", ",");
}
