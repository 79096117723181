<template>
  <v-container>
    <div
      id="beer-submissions"
      v-if="user && publishedMonths && companies && beerSubmissions"
    >
      <v-card class="elevation-0">
        <v-card-title>
          <v-row class="justify-space-between">
            <v-col cols="2">
              <v-select
                :items="years"
                label="År"
                v-model="year"
                @change="yearChange"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                class="mr-6"
                v-model="search"
                append-icon="mdi-magnify"
                label="Søg"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="mt-3 text-end">
              <v-btn class="primary" @click="createNew">Opret ny</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          class="table-cursor"
          :headers="headers"
          :items="submissions"
          :search="search"
          dense
          :options="{ itemsPerPage: 10000 }"
          hide-default-footer
          no-data-text="Ingen indberetninger for år"
          @click:row="rowClick"
        ></v-data-table>
      </v-card>

      <v-dialog v-model="itemDialog" scrollable max-width="1100px">
        <v-card>
          <v-card-title>{{ dialogHeader }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-4">
            <v-form
              v-model="formValid"
              ref="form"
              :disabled="dialogMode === 'edit' && !user.company.admin"
            >
              <v-container>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-menu
                      ref="menu"
                      v-model="monthMenu"
                      :close-on-content-click="false"
                      :return-value.sync="form.month"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.month"
                          label="Måned"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!user.company.admin"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.month"
                        type="month"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="monthMenu = false">
                          Annuller
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(form.month)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="4"
                    ><v-select
                      v-show="user.company.admin"
                      :items="companies"
                      label="Bryggeri"
                      item-text="name"
                      item-value="_id"
                      v-model="form.company"
                    ></v-select
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3>Afgiftsfri øl</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider class="mb-3"></v-divider>
                </v-row>
                <v-row v-for="(beer, key) in form.afgiftsfri" :key="key">
                  <v-col cols="12" md="3" class="pt-1">
                    <strong>{{ beer.title }} - {{ beer.percent }}</strong
                    ><br />{{ beer.description }}
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      dense
                      v-model="beer.hl"
                      :rules="[formRules.requiredRule, formRules.numberRule]"
                      label="Antal HL"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      dense
                      v-model="beer.alc"
                      :rules="[formRules.requiredRule, formRules.numberRule]"
                      label="Liter alkohol"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      dense
                      v-model="beer.ontradehl"
                      :rules="[formRules.requiredRule, formRules.numberRule]"
                      label="Antal HL (Ontrade)"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mb-3">
                  <v-col cols="12" md="3" class="subtitle-1 font-weight-bold"
                    >Total afgiftsfri øl</v-col
                  >
                  <v-col cols="12" md="3" class="subtitle-1 font-weight-bold">{{
                    afgiftsfriHlTotal
                  }}</v-col>
                  <v-col cols="12" md="3" class="subtitle-1 font-weight-bold">{{
                    afgiftsfriAlcTotal
                  }}</v-col>
                  <v-col cols="12" md="3" class="subtitle-1 font-weight-bold">{{
                    afgiftsfriOntradeHlTotal
                  }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3>Afgiftspligtig øl</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider class="mb-3"></v-divider>
                </v-row>
                <v-row v-for="(beer, key) in form.afgiftspligtig" :key="key">
                  <v-col cols="12" md="3" class="pt-1">
                    <strong>{{ beer.title }} - {{ beer.percent }}</strong
                    ><br />{{ beer.description }}
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      dense
                      v-model="beer.hl"
                      :rules="[formRules.requiredRule, formRules.numberRule]"
                      label="Antal HL"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      dense
                      v-model="beer.alc"
                      :rules="[formRules.requiredRule, formRules.numberRule]"
                      label="Liter alkohol"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      dense
                      v-model="beer.ontradehl"
                      :rules="[formRules.requiredRule, formRules.numberRule]"
                      label="Antal HL (Ontrade)"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mb-3">
                  <v-col cols="12" md="3" class="subtitle-1 font-weight-bold"
                    >Total afgiftspligtig øl</v-col
                  >
                  <v-col cols="12" md="3" class="subtitle-1 font-weight-bold">{{
                    afgiftspligtigHlTotal
                  }}</v-col>
                  <v-col cols="12" md="3" class="subtitle-1 font-weight-bold">{{
                    afgiftspligtigAlcTotal
                  }}</v-col>
                  <v-col cols="12" md="3" class="subtitle-1 font-weight-bold">{{
                    afgiftspligtigOntradeHlTotal
                  }}</v-col>
                </v-row>
                <v-row class="mb-3">
                  <v-col cols="12" md="3" class="subtitle-1 font-weight-bold"
                    >Totalt ølsalg</v-col
                  >
                  <v-col cols="12" md="3" class="subtitle-1 font-weight-bold">{{
                    hlTotal
                  }}</v-col>
                  <v-col cols="12" md="3" class="subtitle-1 font-weight-bold">{{
                    alcTotal
                  }}</v-col>
                  <v-col cols="12" md="3" class="subtitle-1 font-weight-bold">{{
                    ontradeHlTotal
                  }}</v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-4" v-if="user.company.admin">
            <div class="d-flex justify-space-between" style="width: 100%">
              <div>
                <v-btn
                  v-if="dialogMode === 'edit'"
                  color="error"
                  text
                  @click="deleteClick"
                >
                  Slet
                </v-btn>
              </div>
              <div>
                <v-btn class="primary--text" text @click="itemDialog = false">
                  Annuller
                </v-btn>
                <v-btn
                  v-if="dialogMode === 'new'"
                  class="primary ml-6"
                  @click="dialogConfirmCreate"
                >
                  Opret
                </v-btn>
                <v-btn
                  v-if="dialogMode === 'edit'"
                  class="primary ml-6"
                  @click="dialogConfirmSave"
                >
                  Gem
                </v-btn>
              </div>
            </div>
          </v-card-actions>
          <v-card-actions class="py-4" v-if="!user.company.admin">
            <div class="d-flex justify-space-between" style="width: 100%">
              <div></div>
              <div>
                <v-btn
                  v-if="dialogMode === 'new'"
                  class="primary--text"
                  text
                  @click="itemDialog = false"
                >
                  Annuller
                </v-btn>
                <v-btn
                  v-if="dialogMode === 'edit'"
                  class="primary--text"
                  text
                  @click="itemDialog = false"
                >
                  Luk
                </v-btn>
                <v-btn
                  v-if="dialogMode === 'new'"
                  class="primary ml-6"
                  @click="dialogConfirmCreate"
                >
                  Opret
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteDialog" max-width="480">
        <v-card>
          <v-card-title class="text-h6">
            Er du sikker på du vil slette?
          </v-card-title>

          <v-card-text>
            Dette vil slette indberetningen permanent og kan ikke fortrydes.
          </v-card-text>

          <v-card-actions class="py-5">
            <v-spacer></v-spacer>

            <v-btn text @click="deleteDialog = false"> Annuller </v-btn>

            <v-btn class="error ml-6" dark text @click="dialogConfirmDelete">
              Slet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import api from "../api/api";
import { mapState, mapActions } from "vuex";
import { required, number } from "../utils/FormRules";
import DateTime from "../utils/DateTime";
import BeerSubmission from "../utils/BeerSubmission";

export default {
  name: "BeerSubmissions",
  data() {
    return {
      monthpicker: null,
      monthMenu: false,
      itemDialog: false,
      dialogMode: null,
      dialogEditId: null,
      deleteDialog: false,
      search: "",
      headers: [
        { text: "År", value: "year", align: "start" },
        { text: "Måned", value: "month" },
        { text: "Bryggeri", value: "company" },
      ],
      defaultForm: {
        company: null,
        month: null,
        afgiftsfri: {
          alkoholfriol: {
            title: "Alkoholfri øl",
            percent: "0,0%-0,5%",
            description: "Alle alkoholfri øl uanset type",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          letol: {
            title: "Letøl",
            percent: "0,6%-2,7%",
            description: "Alle letøl uanset type",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          afgiftsfrifadol: {
            title: "Heraf fadøl",
            percent: "0,0%-2,7%",
            description: "Afgiftsfri fadøl",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
        },
        afgiftspligtig: {
          mildpilsner: {
            title: "Mild Pilsner",
            percent: "2,8% - 4,3%",
            description: "Lys lager: Undergæret, lyse malte",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          pilsner: {
            title: "Pilsner",
            percent: "4,4% - 4,8%",
            description: "Lys lager: Undergæret, lyse malte",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          starkpilsner: {
            title: "Stærk Pilsner",
            percent: "4,9% - 7,1%",
            description: "Lys lager: Undergæret, lyse malte",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          ekstrastarkpilsner: {
            title: "Ekstra Stærk Pilsner",
            percent: "7,2% og derover",
            description: "Lys lager: Undergæret, lyse malte",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          classic: {
            title: "Classic",
            percent: "2,8% og derover",
            description: "Lys lager: Undergæret, lyse malte",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          lagerol: {
            title: "Lagerøl",
            percent: "2,8% og derover",
            description: "Mørk lager: Undergæret, mørke basis- & specialmalte",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          bock: {
            title: "Bock",
            percent: "2,8% og derover",
            description: "Mørk lager: Undergæret, mørke basis- & specialmalte",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          porterstout: {
            title: "Porter/Stout",
            percent: "2,8% og derover",
            description: "Sort farve og tung maltfylde",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          hvedeol: {
            title: "Hvedeøl",
            percent: "2,8% og derover",
            description: "Overgæret med indhold af hvede",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          lysale: {
            title: "Lys ale",
            percent: "2,8%-5,9%",
            description: "Overgæret brygget på lyse malte",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          lysalestark: {
            title: "Lys ale",
            percent: "6,0% og derover",
            description: "Overgæret brygget på lyse malte",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          morkale: {
            title: "Mørk ale",
            percent: "2,8%-5,9%",
            description: "Overgæret brygget på mørke malte",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          morkalestark: {
            title: "Mørk ale",
            percent: "6,0% og derover",
            description: "Overgæret brygget på mørke malte",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          ipa: {
            title: "IPA",
            percent: "2,8%-5,9%",
            description: "Overgæret, bitter smag",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          ipastark: {
            title: "IPA",
            percent: "6,0% og derover",
            description: "Overgæret, bitter smag",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          ovrigeoltyper: {
            title: "Øvrige øltyper",
            percent: "2,8% og derover",
            description: "",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
          afgiftspligtigfadol: {
            title: "Heraf fadøl",
            percent: "2,8% og derover",
            description: "Afgiftspligtig fadøl",
            hl: "0",
            alc: "0",
            ontradehl: "0",
          },
        },
      },
      form: {},
      formValid: false,
      formRules: {
        requiredRule: required,
        numberRule: number,
      },
      year: null,
    };
  },
  mounted() {
    const now = new Date();
    this.year = now.getFullYear().toString();

    this.loadPublishedMonths();
    this.loadCompanies();
    this.loadBeerSubmissions(this.year);
  },
  methods: {
    ...mapActions([
      "loadPublishedMonths",
      "loadCompanies",
      "loadBeerSubmissions",
      "createBeerSubmission",
      "saveBeerSubmission",
      "deleteBeerSubmission",
    ]),
    yearChange() {
      this.loadBeerSubmissions(this.year);
    },
    createNew() {
      // Deep clone of default form values
      const newFormStringified = JSON.stringify(
        Object.assign({}, this.defaultForm)
      );
      const newForm = JSON.parse(newFormStringified);

      // Set month/year and company
      const date = new Date(
        this.beerPublishedMonth.endyear,
        this.beerPublishedMonth.endmonth
      );
      const nextMonth = new Date(date.setMonth(date.getMonth() + 1));
      const monthYear = DateTime.yearMonthToYearmonthstring(
        nextMonth.getFullYear(),
        nextMonth.getMonth()
      );
      newForm.month = monthYear;
      newForm.company = this.user.company._id;

      this.form = newForm;
      this.dialogEditId = null;
      this.dialogMode = "new";
      this.itemDialog = true;
    },
    rowClick(row) {
      // Deep clone of default form values
      const newFormStringified = JSON.stringify(
        Object.assign({}, this.defaultForm)
      );
      const blankForm = JSON.parse(newFormStringified);

      this.form = blankForm;

      this.dialogMode = "edit";
      this.dialogEditId = row._id;
      this.itemDialog = true;

      api.getBeerSubmission(this.token, row._id).then((response) => {
        const newForm = BeerSubmission.submissionToForm(
          blankForm,
          response.data
        );
        this.form = newForm;
      });
    },
    deleteClick() {
      this.deleteDialog = true;
    },
    dialogConfirmCreate() {
      const newSubmission = BeerSubmission.formToSubmission(this.form);
      this.createBeerSubmission(newSubmission);
      this.itemDialog = false;
    },
    dialogConfirmSave() {
      // Deep clone of default form values
      const newFormStringified = JSON.stringify(Object.assign({}, this.form));
      const newForm = JSON.parse(newFormStringified);
      const submission = BeerSubmission.formToSubmission(newForm);

      this.saveBeerSubmission({
        submissionId: this.dialogEditId,
        submission: submission,
      });
      this.itemDialog = false;
    },
    dialogConfirmDelete() {
      this.deleteBeerSubmission({
        submissionId: this.dialogEditId,
        year: this.year,
      });
      this.deleteDialog = false;
      this.itemDialog = false;
    },
  },
  computed: {
    ...mapState([
      "loaded",
      "user",
      "token",
      "companies",
      "beerSubmissions",
      "publishedMonths",
    ]),
    beerPublishedMonth() {
      if (this.publishedMonths) {
        return this.publishedMonths.find((month) => {
          return month.type === "beer";
        });
      }

      return null;
    },
    submissions() {
      return this.beerSubmissions.map((sub) => {
        return {
          _id: sub._id,
          year: sub.year,
          month: DateTime.monthnameFromIndex(sub.month),
          company: sub.company.name,
        };
      });
    },
    dialogHeader() {
      return this.dialogMode == "new" ? "Opret indberetning" : "Indberetning";
    },
    afgiftsfriHlTotal() {
      if (this.form.afgiftsfri) {
        const result =
          BeerSubmission.parseInputToSubmission(
            this.form.afgiftsfri.alkoholfriol.hl
          ) +
          BeerSubmission.parseInputToSubmission(this.form.afgiftsfri.letol.hl);
        const formattedResult = BeerSubmission.parseSubmissionToInput(
          result,
          true
        );
        return isNaN(result) ? "-" : formattedResult;
      }
      return "0";
    },
    afgiftsfriAlcTotal() {
      if (this.form.afgiftsfri) {
        const result =
          BeerSubmission.parseInputToSubmission(
            this.form.afgiftsfri.alkoholfriol.alc
          ) +
          BeerSubmission.parseInputToSubmission(this.form.afgiftsfri.letol.alc);
        const formattedResult = BeerSubmission.parseSubmissionToInput(
          result,
          true
        );
        return isNaN(result) ? "-" : formattedResult;
      }
      return "0";
    },
    afgiftsfriOntradeHlTotal() {
      if (this.form.afgiftsfri) {
        const result =
          BeerSubmission.parseInputToSubmission(
            this.form.afgiftsfri.alkoholfriol.ontradehl
          ) +
          BeerSubmission.parseInputToSubmission(
            this.form.afgiftsfri.letol.ontradehl
          );
        const formattedResult = BeerSubmission.parseSubmissionToInput(
          result,
          true
        );
        return isNaN(result) ? "-" : formattedResult;
      }
      return "0";
    },
    afgiftspligtigHlTotal() {
      if (this.form.afgiftspligtig) {
        let result = 0;
        for (const prop in this.form.afgiftspligtig) {
          if (prop !== "afgiftspligtigfadol") {
            const item = this.form.afgiftspligtig[prop];
            result += BeerSubmission.parseInputToSubmission(item.hl);
          }
        }
        const formattedResult = BeerSubmission.parseSubmissionToInput(
          result,
          true
        );
        return isNaN(result) ? "-" : formattedResult;
      }
      return "0";
    },
    afgiftspligtigAlcTotal() {
      if (this.form.afgiftspligtig) {
        let result = 0;
        for (const prop in this.form.afgiftspligtig) {
          if (prop !== "afgiftspligtigfadol") {
            const item = this.form.afgiftspligtig[prop];
            result += BeerSubmission.parseInputToSubmission(item.alc);
          }
        }
        const formattedResult = BeerSubmission.parseSubmissionToInput(
          result,
          true
        );
        return isNaN(result) ? "-" : formattedResult;
      }
      return "0";
    },
    afgiftspligtigOntradeHlTotal() {
      if (this.form.afgiftspligtig) {
        let result = 0;
        for (const prop in this.form.afgiftspligtig) {
          if (prop !== "afgiftspligtigfadol") {
            const item = this.form.afgiftspligtig[prop];
            result += BeerSubmission.parseInputToSubmission(item.ontradehl);
          }
        }
        const formattedResult = BeerSubmission.parseSubmissionToInput(
          result,
          true
        );
        return isNaN(result) ? "-" : formattedResult;
      }
      return "0";
    },
    hlTotal() {
      const result =
        BeerSubmission.parseInputToSubmission(this.afgiftsfriHlTotal) +
        BeerSubmission.parseInputToSubmission(this.afgiftspligtigHlTotal);
      const formattedResult = BeerSubmission.parseSubmissionToInput(
        result,
        true
      );
      return isNaN(result) ? "-" : formattedResult;
    },
    alcTotal() {
      const result =
        BeerSubmission.parseInputToSubmission(this.afgiftsfriAlcTotal) +
        BeerSubmission.parseInputToSubmission(this.afgiftspligtigAlcTotal);
      const formattedResult = BeerSubmission.parseSubmissionToInput(
        result,
        true
      );
      return isNaN(result) ? "-" : formattedResult;
    },
    ontradeHlTotal() {
      const result =
        BeerSubmission.parseInputToSubmission(this.afgiftsfriOntradeHlTotal) +
        BeerSubmission.parseInputToSubmission(
          this.afgiftspligtigOntradeHlTotal
        );
      const formattedResult = BeerSubmission.parseSubmissionToInput(
        result,
        true
      );
      return isNaN(result) ? "-" : formattedResult;
    },
    years() {
      const start = 2020;
      const end = parseInt(new Date().getFullYear());
      const diff = end - start + 1;

      return [...Array(diff)].map((_, i) => {
        return (end - i).toString();
      });
    },
  },
};
</script>
<style>
.table-cursor tbody tr:hover {
  cursor: pointer;
}
</style>
